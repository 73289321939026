const formatDateTime = (dateTime) => {
    const parsedDate = new Date(dateTime);

    const formatDate = (date) => {
        const monthNames = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        const year = date.getFullYear();
        const month = monthNames[date.getMonth()];
        const day = String(date.getDate()).padStart(2, '0');
        return `${day} ${month} ${year}`;
    };

    const optionsHHMM = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'Asia/Singapore',
        // timeZoneName: 'short',
    };

    const optionsHHMMss = {
        day: '2-digit',
        month: 'short', // Use 'short' for abbreviated month names like 'Jan'
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Singapore',
    };

    const optionsDateOnly = {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'Asia/Singapore',
    };

    const optionsTimeOnly = {
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false,
        timeZone: 'Asia/Singapore',
        // timeZoneName: 'short',
    };

    const formattedDate = formatDate(parsedDate);
    const formattedDateTime = parsedDate.toLocaleString('en-SG', optionsHHMM).replace(/,/g, '');
    const formattedDateTimeFull = parsedDate.toLocaleString('en-SG', optionsHHMMss).replace(/,/g, '');
    const formattedDateTimeForCharts = parsedDate.toLocaleString('en-US', optionsHHMM).replace(/,/g, '');
    const formattedDateOnly = parsedDate.toLocaleString('en-SG', optionsDateOnly).replace(/,/g, '');
    const formattedTime = parsedDate.toLocaleString('en-SG', optionsTimeOnly).replace(/,/g, '');

    return {
        formattedDate,
        formattedDateTime,
        formattedDateTimeForCharts,
        formattedTime,
        formattedDateOnly,
        formattedDateTimeFull
    };
};

export default formatDateTime;