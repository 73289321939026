import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDBox from "../../components/MDBox";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDTypography";
import Footer from "../../examples/Footer";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import Slider from '@mui/material/Slider';
import Box from "@mui/material/Box";
import {useEffect, useState} from "react";
import RangeSlider from "./components/rangeSlider";

const Settings = () => {

    // code for handling temp threshold slider
    const [value, setValue] = useState([16, 35]);
    const handleSliderChange = (event, newValue) => {
        setValue(newValue);
        console.log(event)
    };

    // // Auto-generate report toggle on/off
    // const [checked, setChecked] = useState(false);
    // const handleSwitchChange = () => {
    //     setChecked(!checked);
    // }
    //
    // // if toggled on, then enable this select component
    // const [frequency, setFrequency] = useState('');
    // const handleFrequencyChange = (event) => {
    //     setFrequency(event.target.value);
    // };
    // const [selectFilled, setSelectFilled] = useState(false);
    // useEffect(() => {
    //     if (frequency === "") {
    //         setSelectFilled(false);
    //     } else {
    //         setSelectFilled(true);
    //     }
    // }, [frequency])

    return (
        <DashboardLayout>
            <div style={{display: "flex", flexDirection: "column", minHeight: "95vh", justifyContent: "space-between"}}>
                <div>
                    <DashboardNavbar/>
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Dashboard Settings
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox p={3}>
                                        <MDTypography fontWeight='medium' sx={{mb: 2}}>Temperature
                                            Thresholds</MDTypography>
                                        <Box sx={{display: 'inline-flex', alignItems: 'lef', flexDirection: 'column'}}>
                                            <Box sx={{width: 300, mr: 4}}>
                                                <RangeSlider value={value} onChange={handleSliderChange}/>
                                            </Box>
                                            <MDTypography variant="subtitle2">
                                                Lower limit: <b>{value[0]}°C</b>,
                                                Upper limit: <b>{value[1]}°C</b>
                                            </MDTypography>
                                        </Box>
                                    </MDBox>

                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer/>
            </div>
        </DashboardLayout>
    );
}

export default Settings;