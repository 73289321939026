/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

function ComplexStatisticsCard({ color, deviceName, pointTitle, count, pointTitle2, count2, time, icon }) {
  return (
    <Card>
      <MDBox pt={1} px={3} height="6rem" display="inline-flex" justifyContent="space-between" >
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            {icon}
          </Icon>
        </MDBox>
        <MDBox>
          <MDBox m={2} display="flex" alignItems="center" lineHeight={1.25}>
            <MDTypography mx={1} variant="h5" color="text" fontWeight="light">
              {pointTitle}
            </MDTypography>
            <MDTypography p={1} variant="h3">{count}</MDTypography>
          </MDBox>
          {/*<MDBox mt={1} display="flex" alignItems="center" lineHeight={1.25}>*/}
          {/*  <MDTypography mr={1} variant="button" fontWeight="light" color="text">*/}
          {/*    {pointTitle2}*/}
          {/*  </MDTypography>*/}
          {/*  <MDTypography variant="h4">{count2}</MDTypography>*/}
          {/*</MDBox>*/}
        </MDBox>
      </MDBox>
      <MDBox>
        <MDTypography variant="h6" sx={{ mt: 2, ml: 4, mr: 0.5 }}>{deviceName}</MDTypography>
        {/*<MDTypography variant="h5" fontWeight="regular" color="text" sx={{ mt: 2, ml: 4, mr: 0.5 }}>{deviceName}</MDTypography>*/}
      </MDBox>
      <Divider />
      <MDBox pb={4} px={4} display="flex" alignItems="center">
        <MDTypography variant="button" color="text" lineHeight={1} sx={{ mt: 0.15, mr: 0.5 }}>
          <Icon>schedule</Icon>
        </MDTypography>
        <MDTypography component="p" variant="button" color="text" display="flex">
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            color={time.color}
          >
            {time.amount}
          </MDTypography>
          &nbsp;{time.label}
        </MDTypography>
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
