import React from 'react';
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import deviceTypeConfig from "../../../../utils/deviceTypeConfig";
import IconButton from "@mui/material/IconButton";
import HelpIcon from '@mui/icons-material/Help';

const DeviceEditor = (props) => {

    const ACTION_ADD = 'ADD';
    const ACTION_MODIFY = 'MODIFY';
    const ACTION_DELETE = 'DELETE';

    const handleSubmit = () => {
        // props.submitFunction();

        switch (props.editorAction) {
            case ACTION_ADD: props.addAction(); break;
            case ACTION_MODIFY: props.modifyAction(); break;
            case ACTION_DELETE: props.deleteAction(); break;
            default: return;
        }
        props.setOpen(false);
    };

    const handleClose = () => {
        props.setOpen(false);
    }

    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <MDTypography variant="h6" color="info">
                        {
                            props.editorAction === ACTION_ADD ? 'Add Device' : (
                                props.editorAction === ACTION_MODIFY ? 'Modify Device' : 'Delete Device'
                            )
                        }
                    </MDTypography>
                    <MDTypography variant="h6" color="warning">
                        {props.editorAction === ACTION_DELETE ? 'CAUTION: You cannot undo this action!' : ''}
                    </MDTypography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="deviceName"
                        label="Device Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.deviceName ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setDeviceName(e.target.value)}
                    />
                    <FormControl variant="standard" fullWidth >
                        <InputLabel id="deviceTypeLabel">Device Type</InputLabel>
                        <Select
                            labelId="deviceTypeLabel"
                            id="deviceType"
                            value={props.deviceType ?? '0'}
                            onChange={(e) => props.setDeviceType(e.target.value)}
                            label="Device Type"
                        >
                            {
                                props.editorAction !== ACTION_DELETE && Object.keys(deviceTypeConfig).map((key) => {
                                    return (<MenuItem value={key}>{deviceTypeConfig[key]}</MenuItem>)
                                }) || (
                                    <MenuItem value={props.deviceType}>
                                        {deviceTypeConfig[props.deviceType]}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="mqttTopic"
                        label="MQTT Topic"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.mqttTopic ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setMqttTopic(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="latitude"
                        label="Device Location (Latitude)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={props.deviceLatitude ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setDeviceLatitude(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="longitude"
                        label="Device Location (Longitude)"
                        type="number"
                        fullWidth
                        variant="standard"
                        value={props.deviceLongitude ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setDeviceLongitude(e.target.value)}
                    />
                    <a href="https://support.google.com/maps/answer/18539?hl=en&co=GENIE.Platform%3DDesktop" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <IconButton color="secondary" aria-label="help">
                            <HelpIcon style={{ fontSize: 6 }} />
                        </IconButton>
                        <MDTypography component="span" variant="overline" color="secondary">Get the coordinates (latitude and longitude) of your location</MDTypography>
                    </a>
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose}>Cancel</MDButton>
                    <MDButton onClick={handleSubmit}>Proceed</MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default DeviceEditor;