import HttpService from "./htttp.service";

class CpumetricService {

  getCPUMetric = async () => {
    return await HttpService.get('cpu');
  };

}

export default new CpumetricService();
