import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

// Services and Utilities
import FileService from "services/file-service";
import DeviceService from "services/device-service";
import {AuthContext} from "../../context";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DeviceMap from "./DeviceMap/DeviceMap";

const AGGREGATION_FILE = process.env.REACT_APP_AGGREGATION_FILE;
const DATA_BUCKET = process.env.REACT_APP_DATA_BUCKET;

const DeviceOverview = () => {

    const authContext = useContext(AuthContext);
    const [devices, setDevices] = useState([]); // Devices with their points and latest readings
    // const navigate = useNavigate();
    //
    // const handleDeviceClick = (deviceId) => {
    //     navigate(`/device-info/${deviceId}`);
    // };

    useEffect(() => {
        async function fetchDevices() {

            try {
                const accessibleDevice = authContext.address;
                const aggregation = await FileService.getFileContent(AGGREGATION_FILE, DATA_BUCKET);
                const devices = await DeviceService.listDevices(accessibleDevice);
                const aggMap = {};
                aggregation.forEach(e => {aggMap[e.deviceId+""] = e});
                const deviceEnriched = devices.map((device) => ({
                    ...device,
                    aggregate: aggMap[device.deviceId+""] && aggMap[device.deviceId+""]["mean"] ?
                        aggMap[device.deviceId+""]["mean"] : null
                }));
                setDevices(deviceEnriched);
            } catch (error) {
                console.error('Failed to fetch devices or aggregation data:', error);
            }
        }
        fetchDevices();
    }, []);

    return (
        <DashboardLayout>
            <div style={{ display: "flex", flexDirection: "column", minHeight:"95vh", justifyContent: "space-between" }}>
                <div>
                    <DashboardNavbar />
                    <MDBox pt={6} pb={2}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <MDTypography
                                            variant="h6"
                                            color="white"
                                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                        >
                                            Device Overview
                                        </MDTypography>
                                    </MDBox>
                                    <MDBox py={3} px={2} borderRadius="lg">
                                        <DeviceMap devices={devices} />
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer />
            </div>
        </DashboardLayout>
    );
};

export default DeviceOverview;
