import HttpService from "./htttp.service";

class MeasurementService {

  addMeasurementPoint = async (payload) => {
    return await HttpService.post('measurements', payload);
  };

  listMeasurementPoints = async () => {
    return await HttpService.get('measurements');
  };

  deleteMeasurementPoint = async (deviceId, pointId) => {
    return await HttpService.delete(`measurements/${deviceId}/${pointId}`);
  };

  modifyMeasurementPoint = async (pointId, payload) => {
    return await HttpService.put('measurements/' + pointId, payload);
  };

}

export default new MeasurementService();
