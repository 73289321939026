/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Authentication pages components
import Footer from "layouts/authentication/components/Footer";
import { Typography, List, ListItem, ListItemText } from "@mui/material";
import { useLocation } from "react-router-dom";

// Image
import ctlogo from "assets/images/logo-ct-dark.png";
import splogo from "../../../../assets/images/splogo-white.png";
import sp5glogo from "assets/images/5GIoTlogo.png";

function BasicLayout({ image, children }) {
  const { pathname } = useLocation();
  return (
    <PageLayout>
      {/*<DefaultNavbar*/}
      {/*  action={{*/}
      {/*    type: "external",*/}
      {/*    route: "https://www.sp.edu.sg/industry/5g-aiot",*/}
      {/*    label: "powered by Singapore Polytechnic",*/}
      {/*    color: "dark",*/}
      {/*  }}*/}
      {/*/>*/}
      <MDBox
        sx={{ height: "auto", minHeight: "100vh" }}
        display="flex"
        flexDirection="column"
        minHeight="100vh"
      >
        <MDBox
          position="absolute"
          width="100%"
          minHeight="100vh"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              image &&
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <MDBox display="flex" justifyContent="space-between" maxHeight="4.5rem" style={{ backgroundColor: 'white', padding: '10px 20px' }} >
            <a href="https://www.sp.edu.sg/" style={{ display: 'flex', alignItems: 'center', marginTop: '5px'}}>
                <img height="30px" src={splogo} alt="spLogo" />
            </a>
            <a href="https://www.sp.edu.sg/industry/5g-aiot" style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                <p style={{ color: 'black', marginRight: '8px', fontSize: '14px', paddingTop: '12px' }}>Powered by</p>
                <img height="30px" src={sp5glogo} alt="sp5gLogo" />
            </a>
          </MDBox>
          <MDBox
            position="relative"
            height="100%"
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            paddingTop="4rem"
          >
            <MDBox paddingBottom="2rem" sx={{ textAlign: "center" }}>
              {pathname === "/auth/login" && (
                <MDBox display="flex" width="100%" justifyContent="center" sx={{ zIndex: "99" }}>
                  <MDBox
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding="1.5rem"
                    width="80%"
                  >
                    <Typography variant="h2" style={{ color: "white" }}>
                        SP Smart Campus Sensors Dashboard - Sandbox Trial
                    </Typography>
                    <Typography variant="body2" style={{ color: "white" }} margin="0.5rem 0rem">

                    </Typography>
                    <MDBox
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      marginBottom="1rem"
                    >
                      {/*<Typography variant="body2" fontWeight="700" style={{ color: "white" }}>*/}
                      {/*  You can log in with*/}
                      {/*</Typography>*/}
                      {/*<List dense={true}>*/}
                      {/*  <ListItem>*/}
                      {/*    <ListItemText*/}
                      {/*      disableTypography*/}
                      {/*      primary={*/}
                      {/*        <Typography*/}
                      {/*          variant="body2"*/}
                      {/*          fontWeight="400"*/}
                      {/*          style={{ color: "white" }}*/}
                      {/*        >*/}
                      {/*          Username{" "}*/}
                      {/*          <Typography variant="span" fontWeight="700">*/}
                      {/*            admin@jsonapi.com*/}
                      {/*          </Typography>{" "}*/}
                      {/*          with password{" "}*/}
                      {/*          <Typography variant="span" fontWeight="700">*/}
                      {/*            secret*/}
                      {/*          </Typography>*/}
                      {/*        </Typography>*/}
                      {/*      }*/}
                      {/*    />*/}
                      {/*  </ListItem>*/}
                      {/*</List>*/}
                    </MDBox>
                  </MDBox>
                </MDBox>
              )}
              <MDBox px={1} width="100%" mx="auto" paddingTop="0.5rem">
                <Grid container spacing={1} justifyContent="center" alignItems="center">
                  <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
                    {children}
                  </Grid>
                </Grid>
              </MDBox>
            </MDBox>
          </MDBox>
          {/*<Footer light />*/}
        </MDBox>
      </MDBox>
    </PageLayout>
  );
}

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default BasicLayout;
