import HttpService from "./htttp.service";

class ReportService {

    generateReport = async(startDate, endDate, email) => {
        const payload = {
            start_date: startDate,
            end_date: endDate,
            email: email
        };
        return await HttpService.post('report', payload);
    };

}

export default new ReportService();
