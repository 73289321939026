import { Link } from "react-router-dom";
import {useEffect, useState} from "react";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import AddIcon from "@mui/icons-material/Add";

// Material Dashboard 2 React components
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import Footer from "../../examples/Footer";
import DataTable from "../../examples/Tables/DataTable";

// User Management Components
import AuthService from "../../services/auth-service";
import userTable from "./components/userTable";
import UserEditor from "./components/UserEditor";

const ACTION_ADD = 'ADD';
const ACTION_MODIFY = 'MODIFY';
const ACTION_DELETE = 'DELETE';

const { columns: uCs, rows: uRs } = userTable([]);

const UserManagement = () => {
    const [uColumns, setUColumns] = useState(uCs);
    const [uRows, setURows] = useState(uRs);
    const [webuserId, setWebuserId] = useState(0);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(0);
    const [name, setName] = useState('');
    const [tel, setTel] = useState(0);
    const [address, setAddress] = useState('');
    const [openUserEditor, setOpenUserEditor] = useState(false);
    const [userEditorAction, setUserEditorAction] = useState('');

    const viewCreateUser = () => {
        setWebuserId(0);
        setEmail('');
        setPassword('');
        setRole(0);
        setName('');
        setTel(0);
        setAddress('');
        setUserEditorAction(ACTION_ADD);
        setOpenUserEditor(true);
    }

    const createUser = () => {
        const payload = {
            email: email,
            password: password,
            role: role,
            name: name,
            tel: tel,
            address: address,
        };
        AuthService.addUser(payload).then(() => {
            setEmail('');
            setPassword('');
            setRole(0);
            setName('');
            setTel(0);
            setAddress('');
            listUser();
            console.log('User added successfully');
        }).catch((error) => {
            alert('Error adding User: ' + error.toString());
        });
    }

    const viewModifyUser = async (webuserId) => {
        if(webuserId) {
            const user = await AuthService.getUser(webuserId);
            setWebuserId(user.webuserId);
            setEmail(user.email);
            setPassword(user.password);
            setPassword('');
            setRole(user.role);
            setName(user.name);
            setTel(user.tel);
            setAddress(user.address);
            setUserEditorAction(ACTION_MODIFY);
            setOpenUserEditor(true);
        }
    }

    const modifyUser = () => {
        const payload = {
            email: email,
            // password: password,
            role: role,
            name: name,
            tel: tel,
            address: address,
        };
        if (password && password.trim().length > 0) {
            payload['password'] = password
        }
        AuthService.modifyUser(webuserId, payload).then(() => {
            listUser();
            console.log('User Info modified successfully');
        }).catch((error) => {
            alert('Error modifying user info: ' + error.toString());
        });
    }

    const viewDeleteUser = async (webuserId) => {
        console.log('Delete webuserId： ', webuserId);
            if(webuserId) {
                const user = await AuthService.getUser(webuserId);
                setWebuserId(user.webuserId);
                setEmail(user.email);
                setPassword(user.password);
                setRole(user.role);
                setName(user.name);
                setTel(user.tel);
                setAddress(user.address);
                setUserEditorAction(ACTION_DELETE);
                setOpenUserEditor(true);
            }
    }

    const deleteUser = () => {
        AuthService.deleteUser(webuserId).then(() => {
            listUser();
        }).catch((error) => {
            alert('Error deleting user: ' + error.toString());
        });
    }

    const listUser = () => {
        AuthService.listUser().then((response) => {
            const { columns: c1s, rows: r1s } = userTable(
                response.collection,
                viewModifyUser,
                viewDeleteUser,
            );
            setUColumns(c1s);
            setURows(r1s);
        }).catch(error => {
            alert('Error listing Users: ' + error.toString());
        })
    }

    useEffect(() => {
        listUser();
    }, []);

    return (
        <DashboardLayout>
            <div style={{ display: "flex", flexDirection: "column", minHeight:"95vh", justifyContent: "space-between" }}>
                <div>
                    <DashboardNavbar />
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <MDTypography variant="h6" color="white">
                                          Users Table
                                        </MDTypography>
                                        <MDButton iconOnly={true} size="medium" onClick={viewCreateUser}>
                                          <AddIcon />
                                        </MDButton>
                                        <UserEditor
                                            title="Add User"
                                            open={openUserEditor}
                                            setOpen={setOpenUserEditor}
                                            email={email}
                                            setEmail={setEmail}
                                            password={password}
                                            setPassword={setPassword}
                                            role={role}
                                            setRole={setRole}
                                            name={name}
                                            setName={setName}
                                            tel={tel}
                                            setTel={setTel}
                                            address={address}
                                            setAddress={setAddress}
                                            addAction={createUser}
                                            modifyAction={modifyUser}
                                            deleteAction={deleteUser}
                                            editorAction={userEditorAction}
                                        />
                                    </MDBox>
                                    <MDBox pt={3}>
                                        <DataTable
                                            table={{ columns: uColumns, rows: uRows }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer />
            </div>
        </DashboardLayout>
    );
};

export default UserManagement;
