import HttpService from "./htttp.service";

class DatabaseService {

  exportData = async (payload) => {
    return await HttpService.post('/tables/dumpInCSV', payload);
  };

}

export default new DatabaseService();
