/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Dashboard 2 React components
import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDButton from "../../../components/MDButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import userRoleConfig from "../../../utils/userRoleConfig";
import formatDateTime from "../../../utils/formatDateTime";

const userTable = (
    users,
    viewModifyUser,
    viewDeleteUser,
) => {
    return {
        columns: [
            { Header: "User Id", accessor: "webuserId", align: "center" },
            { Header: "User Name", accessor: "name", align: "left", sorted: "true", userSelect: "true" },
            { Header: "email", accessor: "email" },
            { Header: "Role", accessor: "role", align: "center" },
            { Header: "Address", accessor: "address" },
            { Header: "Date Created", accessor: "dateCreated", align: "center" },
            { Header: "Date Updated", accessor: "dateUpdated", align: "center" },
            { Header: "Edit User", accessor: "editUser", align: "center" },
            // {
            //     Header: "Edit User",
            //     accessor: (user) => (
            //         <MDBox display="flex">
            //             <MDBox>
            //                 <MDButton
            //                     sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
            //                     onClick={() => viewModifyUser(user)}
            //                 >
            //                     <EditOutlinedIcon variant="filled" />
            //                 </MDButton>
            //                 <MDButton
            //                     sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
            //                     onClick={() => viewDeleteUser(user)}
            //                 >
            //                     <DeleteSweepOutlinedIcon variant="filled" />
            //                 </MDButton>
            //             </MDBox>
            //         </MDBox>
            //     ),
            // },
        ],
        rows: users.map((user) => ({
            webuserId: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="bold">
                    {user.webuserId}
                </MDTypography>
            ),
            name: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="bold">
                    {user.name}
                </MDTypography>
            ),
            email: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="bold">
                    {user.email}
                </MDTypography>
            ),
            role: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    {userRoleConfig[user.role+""]}
                </MDTypography>
            ),
            address: (
                <MDTypography component="a" href="#" variant="button" color="text" fontWeight="bold">
                    {user.address}
                </MDTypography>
            ),
            dateCreated: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    {formatDateTime(user.dateCreated).formattedDateTimeFull}
                </MDTypography>
            ),
            dateUpdated: (
                <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
                    {formatDateTime(user.dateUpdated).formattedDateTimeFull}
                </MDTypography>
            ),
            editUser: (
                <MDBox display="flex">
                    <MDBox>
                        <MDButton
                            sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth:15 }}
                            onClick={() => {
                                viewModifyUser(user.webuserId);
                            }}
                        >
                            <EditOutlinedIcon variant="filled" />
                        </MDButton>
                        <MDButton
                            sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth:15 }}
                            onClick={() => viewDeleteUser(user.webuserId)}
                        >
                            <DeleteSweepOutlinedIcon variant="filled" />
                        </MDButton>
                        {/*<MDButton*/}
                        {/*    sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth:15 }}*/}
                        {/*    onClick={() => viewDeleteUser(user)}*/}
                        {/*>*/}
                        {/*    <DeleteSweepOutlinedIcon variant="filled" />*/}
                        {/*</MDButton>*/}
                    </MDBox>
                </MDBox>
            ),
        })),
    };
};

export default userTable;