import React from 'react';
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import userRoleConfig from "../../../utils/userRoleConfig";

const UserEditor = (props) => {
    const ACTION_ADD = 'ADD';
    const ACTION_MODIFY = 'MODIFY';
    const ACTION_DELETE = 'DELETE';
    const handleSubmit = () => {
        //props.submitFunction();
        switch (props.editorAction) {
            case ACTION_ADD: props.addAction(); break;
            case ACTION_MODIFY: props.modifyAction(); break;
            case ACTION_DELETE: props.deleteAction(); break;
            default: return;
        }
        props.setOpen(false);
    };

    const handleClose = () => {
        props.setOpen(false);
    }

    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <MDTypography variant="h6" color="info">
                        {
                            props.editorAction === ACTION_ADD ? 'Add User' : (
                                props.editorAction === ACTION_MODIFY ? 'Modify User' : 'Delete User'
                            )
                        }
                    </MDTypography>
                    <MDTypography variant="h6" color="warning">
                        {props.editorAction === ACTION_DELETE ? 'CAUTION: You cannot undo this action!' : ''}
                    </MDTypography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="userName"
                        label="User Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.name ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setName(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="userEmail"
                        label="User Email"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.email ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setEmail(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="userPassword"
                        label="User Password"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.password ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setPassword(e.target.value)}
                    />
                    <FormControl variant="standard" fullWidth >
                        <InputLabel id="userRoleLabel">User Role</InputLabel>
                        <Select
                            labelId="userRoleLabel"
                            id="role"
                            value={props.role ?? '0'}
                            onChange={(e) => props.setRole(e.target.value)}
                            label="User Role"
                        >
                            {
                                props.editorAction !== ACTION_DELETE && Object.keys(userRoleConfig).map((key) => {
                                    return (<MenuItem value={key}>{userRoleConfig[key]}</MenuItem>)
                                }) || (
                                    <MenuItem value={props.role}>
                                        {userRoleConfig[props.role]}
                                    </MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="address"
                        label="Address"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.address ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setAddress(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose}>Cancel</MDButton>
                    <MDButton onClick={handleSubmit}>Proceed</MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default UserEditor;