export const DEVICE_COLORS = {
    red: "#F44336",
    green: "#4CAF50",
    grey: "#9E9E9E"
};

const THRESHOLDS = {
    "temperature": { lower: 25, upper: 32 },
    "humidity": { lower: 50, upper: 70 },
    "ammoniaLevel": { lower: 2, upper: 4 },
    "co2": { lower: 300, upper: 500 }
};

export const getThresholdColor = (metric, value) => {
    const thresholds = THRESHOLDS[metric];
    if (!thresholds || !value) {
        return DEVICE_COLORS.grey;
    } else if (value < thresholds.upper && value > thresholds.lower) {
        return DEVICE_COLORS.green;
    } else {
        return DEVICE_COLORS.red;
    }
};