import React from 'react';
import { DEVICE_COLORS } from './ColorUtils';

const Legend = () => {
    return (
        <div style={{
            background: '#fff',
            padding: '15px 60px 10px 20px',
            margin: '10px',
            border: '1px solid #ccc',
            boxShadow: '0 2px 2px rgba(0,0,0.1,0.2)'
        }}>
            <h2 style={{ marginTop: 0 }}>Legend</h2>
            <p style={{ margin: '10px 0' }}>
                <span style={{
                    backgroundColor: DEVICE_COLORS.green,
                    width: '12px',
                    height: '12px',
                    display: 'inline-block',
                    marginRight: '8px'
                }}></span>
                Normal Range
            </p>
            <p style={{ margin: '10px 0' }}>
                <span style={{
                    backgroundColor: DEVICE_COLORS.red,
                    width: '12px',
                    height: '12px',
                    display: 'inline-block',
                    marginRight: '8px'
                }}></span>
                Exceeds Limits
            </p>
            <p style={{ margin: '10px 0' }}>
                <span style={{
                    backgroundColor: DEVICE_COLORS.grey,
                    width: '12px',
                    height: '12px',
                    display: 'inline-block',
                    marginRight: '8px'
                }}></span>
                No Data Available
            </p>
        </div>
    );
};

export default Legend;