// components/CustomMarker.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import SensorsIcon from '@mui/icons-material/Sensors';

const CustomMarker = ({ color, deviceType }) => {
    const markerElement = document.createElement('div');
    const root = createRoot(markerElement);
    root.render(
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: `1px solid ${color}`,
            borderRadius: '50%',
            padding: '2px',
            backgroundColor: 'white'
        }}>
            {deviceType === 1 ? (
                <ThermostatIcon style={{ color: color, fontSize: '32px' }} />
            ) : (
                <SensorsIcon style={{ color: color, fontSize: '32px' }} />
            )}
        </div>
    );
    return markerElement;
};

export default CustomMarker;
