import HttpService from "./htttp.service";

class AuthService {
  // authEndpoint = process.env.API_URL;

  addUser = async (payload) => {
    const addUserEndpoint = 'web-users';
    return await HttpService.post('web-users', payload);
  };

  deleteUser = async (webuserId) => {
    return await HttpService.delete('web-users/' + webuserId);
  };

  modifyUser = async (webuserId, payload) => {
    return await HttpService.put('web-users/' + webuserId, payload);
  };

  login = async (payload) => {
    // const loginEndpoint = 'login';
    const loginEndpoint = 'web-users/session';
    const response = await HttpService.post(loginEndpoint, payload);
    // if (response.success) {
    //   fetchUserData();
    // }
    return response;
  };

  register = async (credentials) => {
    const registerEndpoint = 'register';
    return await HttpService.post(registerEndpoint, credentials);
  };

  logout = async () => {
    const logoutEndpoint = 'logout';
    return await HttpService.post(logoutEndpoint);
  };

  forgotPassword = async (payload) => {
    const forgotPassword = 'password-forgot';
    return await HttpService.post(forgotPassword, payload);
  }

  resetPassword = async (credentials) => {
    const resetPassword = 'password-reset';
    return await HttpService.post(resetPassword, credentials);
  }

  getProfile = async() => {
    const getProfile = 'me';
    return await HttpService.get(getProfile);
  }

  updateProfile = async (newInfo) => {
    const updateProfile = "me";
    return await HttpService.patch(updateProfile, newInfo);
  }

  listUser = async () => {
    return await HttpService.get('web-users');
  };

  getCurrentUser = async () => {
    return await HttpService.get('web-users/session');
  };

  getUser = async (webuserId) => {
    return await HttpService.get('web-users/' + webuserId);
  };

}

export default new AuthService();
