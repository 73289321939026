import Axios from "axios";

const API_URL = process.env.REACT_APP_ANALYTICS_API_URL;
Axios.defaults.baseURL = API_URL;
Axios.defaults.withCredentials = true;

export class AnalyticsService {

  _axios = Axios.create();

  addRequestInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.request.use(onFulfilled, onRejected);
  };

  addResponseInterceptor = (onFulfilled, onRejected) => {
    this._axios.interceptors.response.use(onFulfilled, onRejected);
  };

  get = async (url) => await this.request(this.getOptionsConfig("get", url));

  getOptionsConfig = (method, url, data) => {
    return {
      method,
      url,
      data,
      headers: {
        // "Content-Type": "application/vnd.api+json",
        'Content-Type': "application/x-www-form-urlencoded",
        // "Accept": "application/vnd.api+json",
        "Accept": "application/x-www-form-urlencoded",
        'Access-Control-Allow-Credentials': true
      },
    };
  };

  request(options) {
    return new Promise((resolve, reject) => {
      this._axios
          .request(options)
          .then((res) => resolve(res.data))
          .catch((ex) => reject(ex.response.data));
    });
  }
}

export default new AnalyticsService();
