import HttpService from "./htttp.service";

class ReadingService {

  getReadings = async (start, end) => {
    return await HttpService.get(
        `/reading?startTime=${start}&endTime=${end}`
    );
  };

  getReadingsByIdAndDate = async (deviceId, start, end) => {
    return await HttpService.get(
        `/reading/${deviceId}?startTime=${start}&endTime=${end}`
    );
  };

}

export default new ReadingService();
