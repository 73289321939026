import React from 'react';
import MDButton from "../../../../components/MDButton";
import MDTypography from "../../../../components/MDTypography";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

const PointEditor = (props) => {

    const ACTION_ADD = 'ADD';
    const ACTION_MODIFY = 'MODIFY';
    const ACTION_DELETE = 'DELETE';

    const handleSubmit = () => {
        switch (props.editorAction) {
            case ACTION_ADD: props.addAction(); break;
            case ACTION_MODIFY: props.modifyAction(); break;
            case ACTION_DELETE: props.deleteAction(); break;
            default: return;
        }
        props.setOpen(false);
    };

    const handleClose = () => {
        props.setOpen(false);
    }

    return (
        <React.Fragment>
            <Dialog open={props.open} onClose={handleClose}>
                <DialogTitle>
                    <MDTypography variant="h6" color="info">
                        {
                            props.editorAction === ACTION_ADD ? 'Add Measurement Point' : (
                                props.editorAction === ACTION_MODIFY ?
                                    'Modify Measurement Point' : 'Delete Measurement Point'
                            )
                        }
                    </MDTypography>
                    <MDTypography variant="h6" color="warning">
                        {props.editorAction === ACTION_DELETE ? 'CAUTION: You cannot undo this action!' : ''}
                    </MDTypography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pointName"
                        label="Point Name"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.pointName ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setPointName(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pointType"
                        label="Point Type"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.pointType ?? ''}
                        aria-readonly={props.editorAction === ACTION_DELETE}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setPointType(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="chartNumber"
                        label="Chart Number"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.chartNumber ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setChartNumber(e.target.value)}
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="accessRole"
                        label="Access Role"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={props.accessRole ?? ''}
                        contentEditable={props.editorAction !== ACTION_DELETE}
                        onChange={(e) => props.setAccessRole(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <MDButton onClick={handleClose}>Cancel</MDButton>
                    <MDButton onClick={handleSubmit}>Proceed</MDButton>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default PointEditor;
