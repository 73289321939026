import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import {FormHelperText} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import MDSnackbar from "components/MDSnackbar";
import MDTypography from "components/MDTypography";
import Footer from "examples/Footer";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import ReportService from "services/report-service";
// import ResponsiveDateRangePicker from "./components/ResponsiveDateRangePicker";
// import dayjs from "dayjs";
import { DateRange, DateRangePicker } from 'react-date-range';
import {addDays} from "date-fns";
import {useNavigate} from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const Report = () => {

    const [email, setEmail] = useState("");

    const [state, setState] = useState([
        {
            endDate: new Date(),
            startDate: addDays(new Date(), -7),
            key: 'selection'
        }
    ]);

    const [successSB, setSuccessSB] = useState(false);
    const openSuccessSB = () => setSuccessSB(true);
    const closeSuccessSB = () => setSuccessSB(false);

    // Auto-generate report toggle on/off
    const [checked, setChecked] = useState(false);
    const handleSwitchChange = () => {
        setChecked(!checked);
    }

    // if toggled on, then enable this select component
    const [frequency, setFrequency] = useState('');
    const handleFrequencyChange = (event) => {
        setFrequency(event.target.value);
    };

    const navigate = useNavigate();

    const handlePageClose = () => {
        navigate(-1);
    };

    const [selectFilled, setSelectFilled] = useState(false);
    useEffect(() => {
        setSelectFilled(!(frequency === ""));
    }, [frequency]);

    // submit button for generating report
    const handleSubmit = async () => {
        const startDate = state[0].startDate.toISOString().split('T')[0];
        const endDate = state[0].endDate.toISOString().split('T')[0];
        const result = await ReportService.generateReport(startDate, endDate, email);
        if (result && result.toString().toLowerCase() === 'ok') {
            openSuccessSB();
        } else {
            alert("Error Generating Report!");
        }
        handlePageClose();
    }

    return (
        <DashboardLayout>
            <div style={{display: "flex", flexDirection: "column", minHeight: "95vh", justifyContent: "space-between"}}>
                <div>
                    <DashboardNavbar/>
                    <MDBox pt={6} pb={3}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <Card>
                                    <MDBox
                                        mx={2}
                                        mt={-3}
                                        py={3}
                                        px={2}
                                        variant="gradient"
                                        bgColor="info"
                                        borderRadius="lg"
                                        coloredShadow="info"
                                        style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}
                                    >
                                        <MDTypography variant="h6" color="white">
                                            Report
                                        </MDTypography>
                                        <MDBox
                                            style={{display: 'inline-flex', alignItems: 'center',gap: '1rem'}}>
                                            <MDButton iconOnly={true} size="medium" onClick={handlePageClose}>
                                                <CloseIcon />
                                            </MDButton>
                                        </MDBox>
                                    </MDBox>
                                    <MDBox
                                        sx={{
                                            ml: 2,
                                            px: 3,
                                            '@media (max-width: 600px)': {
                                                ml: 0,
                                            }
                                        }}
                                    >
                                        <MDTypography variant="h4" sx={{mb: 2, mt: 4}}>
                                            Generate Report
                                        </MDTypography>
                                        <Box sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            mb: 2,
                                            '@media (max-width: 400px)': {
                                                ml: -3
                                            }
                                        }}>
                                            <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
                                                <Box sx={{
                                                    // apply style for screen width < 600px
                                                    '@media (max-width: 600px)': {
                                                        '.rdrDefinedRangesWrapper': { display: 'none' },
                                                        '.rdrCalendarWrapper': { width: '100%' }
                                                    }
                                                }}>
                                                <DateRangePicker
                                                    onChange={item => setState([item.selection])}
                                                    showSelectionPreview={true}
                                                    moveRangeOnFirstSelection={false}
                                                    months={1}
                                                    ranges={state}
                                                    direction="horizontal"
                                                />
                                                {/*<ResponsiveDateRangePicker value={dateValue} setValue={setDateValue}/>*/}
                                                {/*<DateRange*/}
                                                {/*    editableDateInputs={true}*/}
                                                {/*    onChange={item => setState([item.selection])}*/}
                                                {/*    moveRangeOnFirstSelection={false}*/}
                                                {/*    ranges={state}*/}
                                                {/*/>*/}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            width: '75%',
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                        }}>
                                            {/*<Box sx={{*/}
                                            {/*    display: 'flex',*/}
                                            {/*    alignItems: 'center',*/}
                                            {/*    justifyContent: 'space-between',*/}
                                            {/*    mr: 3*/}
                                            {/*}}>*/}
                                            {/*    /!*<MDTypography variant="body2">E-mail:</MDTypography>*!/*/}
                                            {/*    <MDInput*/}
                                            {/*        sx={{flexGrow: 1, ml: 2}}*/}
                                            {/*        placeholder=""*/}
                                            {/*        value={email}*/}
                                            {/*        onChange={(e) => setEmail(e.target.value)}*/}
                                            {/*        label="Email"*/}
                                            {/*    />*/}
                                            {/*    <MDButton variant="contained" color="info" onClick={handleSubmit}>Submit</MDButton>*/}
                                            {/*</Box>*/}
                                            <Grid container spacing={2} maxWidth="sm">
                                                <Grid item xs={12} md={8}>
                                                        <MDInput
                                                            sx={{width:"100%"}}
                                                            placeholder=""
                                                            value={email}
                                                            onChange={(e) => setEmail(e.target.value)}
                                                            label="Email"/>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <MDButton variant="contained" color="info"
                                                              sx={{width:"100%"}} onClick={handleSubmit}>Submit</MDButton>
                                                </Grid>
                                            </Grid>

                                            <MDSnackbar
                                                sx={{ whiteSpace: 'break-spaces', fontFamily: 'Monospace' }}
                                                color="info"
                                                icon="check"
                                                title="Report Generated"
                                                content={
                                                    '    Using data:' +
                                                    // '\n    From ' + dateValue[0].toISOString().split('T')[0] +
                                                    // '\n    To ' + dateValue[1].toISOString().split('T')[0]
                                                    '\n    From ' + state[0].startDate.toISOString().split('T')[0] +
                                                    '\n      To ' + state[0].endDate.toISOString().split('T')[0]
                                                }
                                                dateTime=""
                                                open={successSB}
                                                close={closeSuccessSB}
                                                onClose={closeSuccessSB}
                                                autoHideDuration={8000}
                                                bgWhite
                                            />
                                        </Box>
                                    </MDBox>
                                    <MDBox
                                        sx={{
                                            ml: 2,
                                            p: 3,
                                            '@media (max-width: 600px)': {
                                                px:1,
                                                py:3
                                            }
                                        }}
                                    >
                                        <MDTypography variant="h4" sx={{mb: 2}}>Automate Report</MDTypography>
                                        <Grid container spacing={2} maxWidth="sm">
                                            <Grid item md={4} xs={7}>
                                                <MDTypography variant="body2">Generate Auto-Report</MDTypography>
                                            </Grid>
                                            <Grid item md={8} xs={5} sx={{justifyContent:"flex-start"}}>
                                                <Switch label="test" checked={checked} onChange={handleSwitchChange}/>
                                            </Grid>
                                            <Grid item md={4} xs={7}>
                                                <MDTypography variant="body2">Report Frequency</MDTypography>
                                            </Grid>
                                            <Grid item md={8} xs={5}>
                                                <FormControl fullWidth variant="filled" error={!selectFilled}>
                                                    <Select
                                                        disabled={!checked}
                                                        value={frequency}
                                                        onChange={handleFrequencyChange}
                                                        size="small">
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        <MenuItem value="Weekly">Weekly</MenuItem>
                                                        <MenuItem value="Bi-Weekly">Bi-Weekly</MenuItem>
                                                        <MenuItem value="Monthly">Monthly</MenuItem>
                                                    </Select>
                                                    {
                                                        (!selectFilled && checked) ?
                                                            <FormHelperText>*Required</FormHelperText> :
                                                            <div></div>
                                                    }
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </MDBox>
                                </Card>
                            </Grid>
                        </Grid>
                    </MDBox>
                </div>
                <Footer/>
            </div>
        </DashboardLayout>
    );
}
export default Report;
