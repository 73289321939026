import React, { useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { Loader } from "@googlemaps/js-api-loader";
import deviceTypeConfig from "../../../utils/deviceTypeConfig";
import Legend from './components/Legend';
import { getThresholdColor } from './components/ColorUtils';
import { applyOffset } from './components/PositionUtils';
import CustomMarker from './components/CustomMarker';
import {useNavigate} from "react-router-dom";

const API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const MAP_ID = process.env.REACT_APP_GOOGLE_MAPS_MAP_ID;
const MAP_CENTRE = { lat: 1.309781, lng: 103.777406 };

const DeviceMap = ({ devices }) => {
    const mapRef = useRef(null);
    const navigate = useNavigate();

    const handleDeviceClick = (deviceId) => {
        navigate(`/device-info/${deviceId}`);
    };


    useEffect(() => {
        const loader = new Loader({
            apiKey: API_KEY,
            version: "weekly",
        });
        let map, infoWindow;

        const setupMap = async () => {
            try {
                const { Map, InfoWindow } = await google.maps.importLibrary("maps");
                const { AdvancedMarkerElement } = await google.maps.importLibrary("marker");
                map = new Map(mapRef.current, { zoom: 17, center: MAP_CENTRE, mapId: MAP_ID });
                infoWindow = new InfoWindow();

                // Create a legend
                const legendDiv = document.createElement('div');
                const root = createRoot(legendDiv); // createRoot(container!) if you use TypeScript
                root.render(<Legend />);
                map.controls[google.maps.ControlPosition.LEFT_TOP].push(legendDiv);

                devices.forEach((device, index) => {
                    if (device.deviceType !== 1) return; // Only show markers for temperature sensor

                    // Apply offset to the position
                    const position = applyOffset(
                        Number(device.deviceLatitude ?? MAP_CENTRE.lat),
                        Number(device.deviceLongitude ?? MAP_CENTRE.lng),
                        index
                    );

                    const pinColor = getThresholdColor("temperature", device.aggregate);

                    // Use CustomMarker component
                    const markerElement = CustomMarker({ color: pinColor, deviceType: device.deviceType });

                    const marker = new AdvancedMarkerElement({
                        position,
                        map,
                        title: `Device: ${device.deviceName}`,
                        content: markerElement,
                        gmpClickable: true,
                    });

                    marker.addListener("click", async () => {
                        const contentString = `<div>
                            <h2>Device: <a href="#" id="device-link-${device.deviceId}">${device.deviceName}</a></h2>
                            <p>Type: ${deviceTypeConfig[device.deviceType] || 'Unknown Type'}</p>
                            <h3>Monthly Average Temperature: </h3>
                            <p>${device.aggregate}</p>
                            </div>`;

                        // Append device reading points to the content string
                        // device.points.forEach((point, index) => {
                        //     const reading = point.latestReading ?? 'N/A';
                        //
                        //     contentString += `<div>
                        //         <h3>${point.pointName}</h3>
                        //         <p>${reading}</p>
                        //     </div>`;
                        // });

                        infoWindow.setContent(contentString);
                        infoWindow.open(map, marker);

                        // Use a timeout to ensure the content is rendered before adding the event listener
                        setTimeout(() => {
                            const link = document.getElementById(`device-link-${device.deviceId}`);
                            if (link) {
                                link.addEventListener('click', (e) => {
                                    e.preventDefault();
                                    handleDeviceClick(device.deviceId);
                                });
                            }
                        }, 100);

                        // // Add event listener directly after setting content
                        // const link = document.getElementById(`device-info/${device.deviceId}`);
                        // if (link) {
                        //     link.addEventListener('click', (e) => {
                        //         e.preventDefault();
                        //         handleDeviceClick(device.deviceId);
                        //         console.log(device.deviceId);
                        //     });
                        // }

                    });
                });
            } catch (error) {
                console.error('Error initializing map', error);
            }
        };

        loader.load().then(setupMap);

        // Cleanup function to reset map-specific variables and potentially close open InfoWindows
        return () => {
            infoWindow?.close();
            map = null;
            infoWindow = null;
        };
    }, [devices]);

    return <div ref={mapRef} style={{ width: '100%', height: '65vh' }}></div>;
};

export default DeviceMap;
