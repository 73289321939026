import React from "react";
import PropTypes from "prop-types";

import MDBox from "../../components/MDBox";

function Background({ children, image }) {
    return (
        <MDBox
            position="relative"
            minHeight="18.75rem"
            // borderRadius="xl"
            sx={{
                // background: ({ palette }) => `linear-gradient(rgba(205, 191, 191, 0.8), rgba(191, 191, 205, 0.8)), url(${image})`,
                backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
                    `${linearGradient(
                        rgba(gradients.dark.main, 0.8),
                        rgba(gradients.dark.state, 0.8)
                    )}, url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "50%",
                overflow: "hidden",
                height: "100vh",
            }}
        >
            <MDBox
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                sx={{
                    overflowY: "auto", // Enable vertical scrolling
                }}
            >
                {children}
            </MDBox>
        </MDBox>
    );
}

Background.propTypes = {
    children: PropTypes.node,
    image: PropTypes.string.isRequired,
};

export default Background;