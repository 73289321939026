/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
 =========================================================
 * Material Dashboard 2 React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
//
// import React from "react";
// import MDBox from "../../../components/MDBox";
// import MDTypography from "../../../components/MDTypography";
// import MDBadge from "../../../components/MDBadge";
// import MDButton from "../../../components/MDButton";
// import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
// import ClearOutlined from '@mui/icons-material/ClearOutlined';
// import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
// import ContentCopyIcon from '@mui/icons-material/ContentCopy';
// import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
// import deviceTypeConfig from "../../../utils/deviceTypeConfig";
// import deviceModeConfig from "../../../utils/deviceModeConfig";
// import formatDeviceData from "../../../utils/deviceDataFormat";
//
// const DeviceTable = (
//     devices,
//     devicePoints,
//     viewModifyDevice,
//     viewDeleteDevice,
//     viewAddPoint,
//     viewModifyPoint,
//     viewDeletePoint,
//     handleDeviceClick,
//     handleDeviceLocationClick,
//     role
// ) => {
//     const formattedDevices = formatDeviceData(devices);
//     const showCol = role > 0;
//
//     const Topic = ({ topic }) => {
//         const handleCopyClick = async () => {
//             try {
//                 await navigator.clipboard.writeText(topic);
//                 alert("Copied MQTT Topic to clipboard:\n" + topic);
//             } catch (err) {
//                 console.error("Unable to copy MQTT Topic to clipboard.", err);
//                 alert("Copy to clipboard failed.");
//             }
//         };
//
//         return (
//             <MDBox display="flex" lineHeight={1}>
//                 <MDBox>
//                     <MDButton
//                         size="medium"
//                         variant="text"
//                         iconOnly={true}
//                         color="text"
//                         onClick={handleCopyClick}
//                     >
//                         <ContentCopyIcon variant="filled" />
//                     </MDButton>
//                     <MDTypography component="span" variant="caption" color="text" fontWeight="regular">
//                         {topic}
//                     </MDTypography>
//                 </MDBox>
//             </MDBox>
//         );
//     };
//
//     // Define sortable accessor functions for each column
//     const getDeviceNameValue = (row) => row.deviceName.toLowerCase();
//     const getLastReadingValue = (row) => parseFloat(row.lastReading) || -Infinity;
//     const getDaysGT25Value = (row) => parseInt(row.daysGT25) || 0;
//     const getLastUpdatedValue = (row) => new Date(row.dateUpdated).getTime();
//     const getThresholdValue = (row) => {
//         const threshold = row.threshold || (row.deviceName.toLowerCase().includes('lab') ? 24 : 25);
//         return parseFloat(threshold);
//     };
//
//     const columns = [
//         {
//             Header: "Device Name",
//             accessor: "deviceName",
//             align: "left",
//             sortType: (rowA, rowB) => {
//                 const a = getDeviceNameValue(rowA.original);
//                 const b = getDeviceNameValue(rowB.original);
//                 return a > b ? 1 : a < b ? -1 : 0;
//             }
//         },
//         ...(showCol ? [{
//             Header: "Device Type",
//             accessor: "deviceType",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const a = deviceTypeConfig[rowA.original.deviceType + ""] || "";
//                 const b = deviceTypeConfig[rowB.original.deviceType + ""] || "";
//                 return a.localeCompare(b);
//             }
//         }] : []),
//         ...(showCol ? [{
//             Header: "MQTT Topic",
//             accessor: "topic",
//             sortType: (rowA, rowB) => {
//                 const a = rowA.original.deviceMqttTopic;
//                 const b = rowB.original.deviceMqttTopic;
//                 return a.localeCompare(b);
//             }
//         }] : []),
//         ...(showCol ? [{ Header: "Measuring Points", accessor: "measuringPoints", align: "center", sortable: false }] : []),
//         {
//             Header: "Latest Reading",
//             accessor: "lastReading",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const a = getLastReadingValue(rowA.original);
//                 const b = getLastReadingValue(rowB.original);
//                 return a - b;
//             }
//         },
//         {
//             Header: "Last Updated",
//             accessor: "lastUpdated",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const a = getLastUpdatedValue(rowA.original);
//                 const b = getLastUpdatedValue(rowB.original);
//                 return a - b;
//             }
//         },
//         ...(showCol ? [{
//             Header: "Threshold",
//             accessor: "threshold",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const a = getThresholdValue(rowA.original);
//                 const b = getThresholdValue(rowB.original);
//                 return a - b;
//             }
//         }] : []),
//         {
//             Header: "Mode of Control",
//             accessor: "modeOfControl",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const a = deviceModeConfig[rowA.original.deviceType + ""] || "";
//                 const b = deviceModeConfig[rowB.original.deviceType + ""] || "";
//                 return a.localeCompare(b);
//             }
//         },
//         {
//             Header: "No. of Days < 25 °C in the current month",
//             accessor: "daysGT25",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const a = getDaysGT25Value(rowA.original);
//                 const b = getDaysGT25Value(rowB.original);
//                 return a - b;
//             }
//         },
//         ...(showCol ? [{
//             Header: "Device Location",
//             accessor: "location",
//             align: "center",
//             sortType: (rowA, rowB) => {
//                 const aLoc = `${rowA.original.deviceLatitude},${rowA.original.deviceLongitude}`;
//                 const bLoc = `${rowB.original.deviceLatitude},${rowB.original.deviceLongitude}`;
//                 return aLoc.localeCompare(bLoc);
//             }
//         }] : []),
//         ...(showCol ? [{ Header: "Edit Device", accessor: "editDevice", align: "center", sortable: false }] : []),
//     ].filter(Boolean);
//
//     // Define rows (rest of the rows code remains the same)
//     const rows = formattedDevices.map((device) => {
//         const baseRow = {
//             deviceName: (
//                 <MDTypography
//                     component="a"
//                     href="#"
//                     variant="button"
//                     color="text"
//                     fontWeight="bold"
//                     onClick={(e) => {
//                         e.preventDefault();
//                         handleDeviceClick(device.deviceId)
//                     }}
//                 >
//                     {device.deviceName.startsWith("Airwits")
//                         ? device.deviceName.slice(13)
//                         : device.deviceName}
//                 </MDTypography>
//             ),
//             lastReading: (
//                 <MDBadge
//                     badgeContent={(device.lastReading !== 'Loading...')
//                         ? `${device.lastReading}\u00B0C`
//                         : 'Loading...'}
//                     variant="contained"
//                     color={device.lastReading >= 25 ? 'success' : 'error'}
//                     container
//                     component="a"
//                     href="#"
//                     onClick={(e) => {
//                         e.preventDefault();
//                         handleDeviceClick(device.deviceId)
//                     }}
//                 />
//             ),
//             modeOfControl: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                     {deviceModeConfig[device.deviceType + ""]}
//                 </MDTypography>
//             ),
//             daysGT25: (
//                 <MDBadge
//                     badgeContent={device.daysGT25}
//                     variant="contained"
//                     color={device.daysGT25 < 3 ? 'success' : 'error'}
//                     container
//                     component="a"
//                     href="#"
//                     onClick={(e) => {
//                         e.preventDefault();
//                         handleDeviceClick(device.deviceId)
//                     }}
//                 />
//             ),
//             lastUpdated: (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                     {device.dateUpdated}
//                 </MDTypography>
//             ),
//             // Add the original values to the row object for sorting
//             original: device
//         };
//
//         if (showCol) {
//             baseRow['topic'] = <Topic topic={device.deviceMqttTopic} />;
//             baseRow['measuringPoints'] = (
//                 <MDButton>
//                     {!devicePoints[device.deviceId] ? (
//                         <b></b>
//                     ) : (
//                         devicePoints[device.deviceId].map((point) => (
//                             <span key={point.pointId}>
//                                 <MDTypography
//                                     sx={{ padding: 1, margin: 1, minHeight: 5, maxHeight: 10 }}
//                                     component="a"
//                                     href="#"
//                                     variant="caption"
//                                     color="text"
//                                     fontWeight="medium"
//                                     onClick={() => viewModifyPoint(point)}
//                                 >
//                                     {point.pointName}
//                                 </MDTypography>
//                                 <MDButton
//                                     sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 10, minHeight: 5, maxHeight: 10 }}
//                                     onClick={() => viewDeletePoint(point)}
//                                 >
//                                     <ClearOutlined />
//                                 </MDButton>
//                                 <br />
//                             </span>
//                         ))
//                     )}
//                     <MDButton
//                         sx={{ padding: 1, margin: 1, minHeight: 5, maxHeight: 10 }}
//                         onClick={() => viewAddPoint(device)}
//                     >
//                         <LibraryAddOutlinedIcon variant="outlined" />
//                     </MDButton>
//                 </MDButton>
//             );
//             baseRow['deviceType'] = (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                     {deviceTypeConfig[device.deviceType + ""]}
//                 </MDTypography>
//             );
//             baseRow['threshold'] = (
//                 <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
//                     {device.threshold
//                         ? `${device.threshold}°C`
//                         : `${device.deviceName.toLowerCase().includes('lab') ? 24 : 25}°C`}
//                 </MDTypography>
//             );
//             baseRow['location'] = (
//                 <MDTypography
//                     component="a"
//                     variant="caption"
//                     color="text"
//                     fontWeight="medium"
//                     onClick={() => handleDeviceLocationClick(device)}
//                     style={{ cursor: 'pointer' }}
//                 >
//                     {(device.deviceLatitude ?? '1.3102731537435788') + ', ' + (device.deviceLongitude ?? '103.7803697688016')}
//                 </MDTypography>
//             );
//             baseRow['editDevice'] = (
//                 <MDBox display="flex">
//                     <MDBox>
//                         <MDButton
//                             sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
//                             onClick={() => viewModifyDevice(device)}
//                         >
//                             <EditOutlinedIcon variant="filled" />
//                         </MDButton>
//                         <MDButton
//                             sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
//                             onClick={() => viewDeleteDevice(device)}
//                         >
//                             <DeleteSweepOutlinedIcon variant="filled" />
//                         </MDButton>
//                     </MDBox>
//                 </MDBox>
//             );
//         }
//         return baseRow;
//     });
//
//     return { columns, rows };
// };
//
// export default DeviceTable;

// Material Dashboard 2 React components
import React from "react";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDBadge from "../../../components/MDBadge";
import MDButton from "../../../components/MDButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ClearOutlined from '@mui/icons-material/ClearOutlined';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import deviceTypeConfig from "../../../utils/deviceTypeConfig";
import deviceModeConfig from "../../../utils/deviceModeConfig";
import formatDeviceData from "../../../utils/deviceDataFormat";

const DeviceTable = (
    devices,
    devicePoints,
    viewModifyDevice,
    viewDeleteDevice,
    viewAddPoint,
    viewModifyPoint,
    viewDeletePoint,
    handleDeviceClick,
    handleDeviceLocationClick,
    role
) => {
    const formattedDevices = formatDeviceData(devices);
    const showCol = role > 0;

    const Topic = ({ topic }) => {
        const handleCopyClick = async () => {
            try {
                await navigator.clipboard.writeText(topic);
                alert("Copied MQTT Topic to clipboard:\n" + topic);
            } catch (err) {
                console.error("Unable to copy MQTT Topic to clipboard.", err);
                alert("Copy to clipboard failed.");
            }
        };

        return (
            <MDBox display="flex" lineHeight={1}>
                <MDBox>
                    <MDButton
                        size="medium"
                        variant="text"
                        iconOnly={true}
                        color="text"
                        onClick={handleCopyClick}
                    >
                        <ContentCopyIcon variant="filled" />
                    </MDButton>
                    <MDTypography component="span" variant="caption" color="text" fontWeight="regular">
                        {topic}
                    </MDTypography>
                </MDBox>
            </MDBox>
        );
    };

    const columns = [
        { Header: "Device Name", accessor: "deviceName", align: "left", userSelect: true },
        ...(showCol ? [{ Header: "Device Type", accessor: "deviceType", align: "center" }] : []),
        ...(showCol ? [{ Header: "MQTT Topic", accessor: "topic" }] : []),
        ...(showCol ? [{ Header: "Measuring Points", accessor: "measuringPoints", align: "center" }] : []),
        { Header: "Latest Reading", accessor: "lastReading", align: "center" },
        { Header: "Last Updated", accessor: "lastUpdated", align: "center" },
        ...(showCol ? [{ Header: "Threshold", accessor: "threshold", align: "center" }] : []),
        { Header: "Mode of Control", accessor: "modeOfControl", align: "center" },
        { Header: "No. of Days < 25 °C in the current month", accessor: "daysGT25", sorted: true, align: "center" },
        ...(showCol ? [{ Header: "Device Location", accessor: "location", align: "center" }] : []),
        ...(showCol ? [{ Header: "Edit Device", accessor: "editDevice", align: "center" }] : []),
    ].filter(Boolean);

    // Define rows
    const rows = formattedDevices.map((device) => {
        const baseRow = {
            deviceName: (
                <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    color="text"
                    fontWeight="bold"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDeviceClick(device.deviceId)
                    }}
                >
                    {device.deviceName.startsWith("Airwits")
                        ? device.deviceName.slice(13)
                        : device.deviceName}
                </MDTypography>
            ),
            lastReading: (
                <MDBadge
                    badgeContent={(device.lastReading !== 'Loading...')
                        ? `${device.lastReading}\u00B0C`
                        : 'Loading...'}
                    variant="contained"
                    color={device.lastReading >= 25 ? 'success' : 'error'}
                    container
                    component="a"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDeviceClick(device.deviceId)
                    }}
                />
            ),
            modeOfControl: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {deviceModeConfig[device.deviceType + ""]}
                </MDTypography>
            ),
            daysGT25: (
                <MDBadge
                    badgeContent={device.daysGT25}
                    variant="contained"
                    color={device.daysGT25 < 3 ? 'success' : 'error'}
                    container
                    component="a"
                    href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        handleDeviceClick(device.deviceId)
                    }}
                />
            ),
            lastUpdated: (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {device.dateUpdated}
                </MDTypography>
            ),
        };

        if (showCol) {
            baseRow['topic'] = <Topic topic={device.deviceMqttTopic} />;
            baseRow['measuringPoints'] = (
                <MDButton>
                    {!devicePoints[device.deviceId] ? (
                        <b></b>
                    ) : (
                        devicePoints[device.deviceId].map((point) => (
                            <span key={point.pointId}>
                                <MDTypography
                                    sx={{ padding: 1, margin: 1, minHeight: 5, maxHeight: 10 }}
                                    component="a"
                                    href="#"
                                    variant="caption"
                                    color="text"
                                    fontWeight="medium"
                                    onClick={() => viewModifyPoint(point)}
                                >
                                    {point.pointName}
                                </MDTypography>
                                <MDButton
                                    sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 10, minHeight: 5, maxHeight: 10 }}
                                    onClick={() => viewDeletePoint(point)}
                                >
                                    <ClearOutlined />
                                </MDButton>
                                <br />
                            </span>
                        ))
                    )}
                    <MDButton
                        sx={{ padding: 1, margin: 1, minHeight: 5, maxHeight: 10 }}
                        onClick={() => viewAddPoint(device)}
                    >
                        <LibraryAddOutlinedIcon variant="outlined" />
                    </MDButton>
                </MDButton>
            );
            baseRow['deviceType'] = (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {deviceTypeConfig[device.deviceType + ""]}
                </MDTypography>
            );
            baseRow['threshold'] = (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium">
                    {device.threshold
                        ? `${device.threshold}°C`
                        : `${device.deviceName.toLowerCase().includes('lab') ? 24 : 25}°C`}
                </MDTypography>
            );
            baseRow['location'] = (
                <MDTypography component="a" variant="caption" color="text" fontWeight="medium"
                              onClick={() => { // when clicking the location details, the mapModal will pop up.
                                  handleDeviceLocationClick(device)
                              }}
                              style={{ cursor: 'pointer' }}
                >
                    {(device.deviceLatitude ?? '1.3102731537435788') + ', ' + (device.deviceLongitude ?? '103.7803697688016')}
                </MDTypography>
            );
            baseRow['editDevice'] = (
                <MDBox display="flex">
                    <MDBox>
                        <MDButton
                            sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
                            onClick={() => viewModifyDevice(device)}
                        >
                            <EditOutlinedIcon variant="filled" />
                        </MDButton>
                        <MDButton
                            sx={{ padding: 1, margin: 1, minWidth: 5, maxWidth: 15 }}
                            onClick={() => viewDeleteDevice(device)}
                        >
                            <DeleteSweepOutlinedIcon variant="filled" />
                        </MDButton>
                    </MDBox>
                </MDBox>
            );
        }
        return baseRow;
    });

    return { columns, rows };
};

export default DeviceTable;
