import AnalyticsService from "./analytics.service";

class AggregationService {

  getExceedingDayCount = async (username, password) => {
    return await AnalyticsService.get(`/exceeding?username=${username}&password=${password}`);
  };

}

export default new AggregationService();
